<template>
  <div id="container-login">
    <div class="circle-white"></div>
    <div class="container-login-internal animated fadeInDown">
        <div class="login">
            <div class="margin-login-mobile">
                <img src="@/assets/images/logo/logo_oficial.png" alt="login" class="img-responsive">                 
                <h3><strong>Ingresa a tu cuenta</strong></h3>
                <p>Podrás ver detalles de envíos y personalizar tu experiencia</p>
                <validation-observer
                    ref="loginForm"
                    #default="{invalid}"
                >
                    <b-form class="form-login" @submit.prevent="login">
                        <b-form-group class="margin-input-email">
                            <validation-provider #default="{ errors }"  name="Email" rules="required|email">
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">                       
                                    <b-form-input
                                    id="email"
                                    v-model="userEmail"
                                    :disabled="loading"
                                    name="login-email"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Email"
                                    class="form-control-merge customize-input"
                                    />
                                    <b-input-group-append is-text class="customize-input">
                                        <feather-icon class="cursor-pointer" icon="XCircleIcon" @click="clean"/>
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group class="mt-1">
                            <validation-provider #default="{ errors }" name="Password" rules="required">
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                                    <b-form-input
                                    id="password"
                                    :disabled="loading"
                                    v-model="password"
                                    :type="passwordFieldType"
                                    class="form-control-merge customize-input"
                                    :state="errors.length > 0 ? false:null"
                                    name="login-password"
                                    :placeholder="$t('Contraseña')"
                                    />

                                    <b-input-group-append is-text class="customize-input">
                                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-link :to="{name:'recovery'}" class="lnk lnk-custom-blue push-right">
                                <small>{{$t('¿Olvidaste tu contraseña?')}}</small>
                            </b-link>
                        </b-form-group>
                        <div id="recaptcha" class="text-center center-recaptcha"></div>
                        <b-button class="mt-1 color-btn-login" type="submit" block :disabled="invalid || loading">
                            <feather-icon v-if="loading" icon="LoaderIcon" class="spinner"/>
                            {{$t('Iniciar sesión')}}
                        </b-button>
                    </b-form>
                </validation-observer>
            </div>
        </div>
        <div class="animation-icon">
            <div class="text-hide-mobile">
                <h2>Automatiza fácilmente</h2>
                <h2 class="m-title-animation">tus procesos con nosotros</h2>
            </div>
            <div class="carousel-content">
                <div class="carousel-box">
                    <div class="carousel-item-text">
                        <li id="animation-delay-1" class="icon-delay"><img src="@/assets/images/login/check.png" width= "40"  alt="">Integra tu e-Commerce</li>             
                    </div>
                    <div class="carousel-item-text">   
                        <li id="animation-delay-2" class="icon-delay"><img src="@/assets/images/login/check.png" width= "40"  alt="">Trabaja con múltiples couriers</li>                 
                    </div>
                    <div class="carousel-item-text">
                        <li id="animation-delay-3" class="icon-delay"><img src="@/assets/images/login/check.png" width= "40"  alt="">Genera etiquetas de tu Marketplace</li>                         
                    </div>
                    <div class="carousel-item-text">
                        <li id="animation-delay-4" class="icon-delay"><img src="@/assets/images/login/check.png" width= "40"  alt="">Accede a reportería personalizada</li>                         
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer-login">{{ year }} Envíame. Todos los derechos reservados ©</footer>       
    </div>   
  </div>
</template>

<script>
import Vue from 'vue'
// import { VueReCaptcha } from 'vue-recaptcha-v3'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BLink, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import LoginService from './login.service'
import { mapGetters } from 'vuex'
import { environment } from '@/environments/environment'

// eslint-disable-next-line no-undef
const googleRecaptcha = grecaptcha.enterprise

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // validation rules
      required,
      email,
      datos: null,
      loading: false,
      loggedUser: {},
      attempt: 0,
      tokenRecaptcha: 'norendered',
      widget: null,
      loginService: new LoginService(this),
      year: new Date().getFullYear()
    }
  },
  mounted () {
    this.showRecaptcha('recaptcha', this.attempt, 0)
  },
  watch: {
    userAlerts (curr) {
      this.loading = false
      if (curr.status === 'ok') {
        // this.$recaptchaInstance.hideBadge()
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon' 
    },
    ...mapGetters({
      userAlerts: 'getUserAlerts'
    })
  },
  methods: {
    verifyCallback (token) {
      this.enableButton = true
      this.tokenRecaptcha = token
    },
    showRecaptcha (element, attempt, maxAttempt) {  
      if (attempt >= maxAttempt) {
        this.enableButton = false
        // Validar que este antes de renderizar
        if (typeof googleRecaptcha !== 'undefined' && googleRecaptcha.render) {
          if (this.tokenRecaptcha === 'norendered') {
            this.widget = googleRecaptcha.render(element, {
              'sitekey' : environment.recaptchaKey,
              'callback' : (token) => this.verifyCallback(token)
            })
          } else if (!this.widget) googleRecaptcha.reset(this.widget)
          this.tokenRecaptcha = 'notoken'
        }
      }
    },
    async login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.attempt++
          if (this.tokenRecaptcha === 'norendered') {
            this.loading = true
            this.authentication()
          } else if (this.tokenRecaptcha === 'notoken') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title:'Oops',
                icon: 'warning',
                variant: 'danger',
                text: 'Debe marcar la casilla "No soy un robot"'
              }
            })
          } else {
            this.loading = true
            // this.$recaptcha('loginForm').then((token) => {
            // this.tokenRecaptcha = token
            const params = { response: this.tokenRecaptcha, expectedAction: 'login' }
            this.loginService.callService('validateRecaptcha', params, params)
              .then(resp => {
                if (resp.data.tokenProperties?.valid) {
                  this.authentication()
                } else {
                  this.showRecaptcha('recaptcha', this.attempt, 0)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title:'Oops',
                      icon: 'warning',
                      variant: 'danger',
                      text: `Hemos detectado actividad sospechosa para iniciar sesión. Por favor reintenta y si sigue pasando, contactate con nosotros. \n \n Problema: ${resp.reasons[0] || 'INVALID_REASON'}`
                    }
                  })
                  this.loading = false
                }
              })
              .catch(err => {
                this.showRecaptcha('recaptcha', this.attempt, 0)
                this.loading = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title:'Oops',
                    icon: 'warning',
                    variant: 'danger',
                    text: `Hemos detectado problemas con la verificación de reCAPTCHA.\n \n Problema: ${err}`
                  }
                })
              })
            // })
          }
        } else {
          this.showRecaptcha('recaptcha', this.attempt, 0)
        }
      })
    },
    authentication () {
      this.$root.$data.auth
        .signInWithEmailAndPassword(this.userEmail, this.password)
        .then((data) => {
          this.$root.$data.auth.setPersistence(this.$root.$data.fb.auth.Auth.Persistence.LOCAL)
          this.loggedUser = data.user
          return data.user.getIdToken()
        })
        .then((token) => {
          this.$store.dispatch('fetchUserData', {id: this.loggedUser.uid, token, from: 'login', remember: this.status})
        })
        .catch((err) => {
          this.showRecaptcha('recaptcha', this.attempt, 0)
          this.loading = false
          console.error(err)
          let message = err.message
          if (['auth/wrong-password', 'auth/user-not-found'].includes(err.code)) {
            message = 'Correo o contraseña incorrectos'
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title:'Error',
              icon: 'WarningIcon',
              variant: 'danger',
              text: message
            }
          })
        })
    },
    clean () {
      this.userEmail = ''
    }
  }
}
</script>

<style lang="scss">
.circle-white {
    background-color: #FFFF;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    z-index: 2;
    width: 100%;
    height: 200%;
    transform: translate(-50%, -25%)
}
#container-login {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: linear-gradient(90deg, #395494 0%, #31AEE4 100%);
    min-height: 100vh;
}
.container-login-internal {
    position: relative;
    z-index: 100 !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
}
.login h3 {
    font-size: 16px;
    margin: 5px 0;
}
.login p {
    font-size: 12.5px;
    margin: 0 0 10px;
}
.login, .animation-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem; 
}
.login .lnk-custom-blue, .login p, .login h3 {
    color: rgba(24, 25, 94, 1);
}
.m-title-animation{
    margin-bottom: 3rem !important;
}
.animation-icon {
    color: #fff;
    align-items: inherit;
    padding: 4rem 8rem;
}
.animation-icon div h2 {
    font-weight: 600;
    margin: 0;
    color: #fff;
}
.icon-delay {
    list-style:none; 
    margin-bottom: 3.5rem;
    font-size: 18px;
    font-weight: 500;
    -moz-animation: show-icon 4.5s;
    -o-animation: show-icon 4.5s;
    -webkit-animation: show-icon 4.5s;
    animation: show-icon 4.5s;
}
#animation-delay-4 {
    animation-delay: -1s;
}

#animation-delay-3 {
    animation-delay: -1.5s;
}

#animation-delay-2 {
    animation-delay: -2s;
}

#animation-delay-1 {
    animation-delay: -2.5s;
}
/* Animación para mostrar/ocultar los iconos y textos*/
@-moz-keyframes show-icon {        
    from {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes show-icon {        
    from {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes show-icon {        
    from {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} 
.animation-icon img {
    margin-right: 2.5rem;
}
.login img{
    width: 50%;
    padding-bottom: 1.5rem;
}
.center-recaptcha {
    justify-content: center;
    margin: 3rem 0;
}
.color-btn-login {
    background: linear-gradient(90deg, #F49143 -21.52%, #F4BB43 104.43%);
    color: #FFFFFF;
    border: none;
    height: 3rem;
    font-weight: 700;
}
.color-btn-login:hover {
    background: linear-gradient(90deg, #f5852c -21.52%, #f7b52c 104.43%);
    color: #FFFFFF;
}
.customize-input, .customize-input:focus, .customize-input .input-group-text {
    background: #F0EFF8;
    color: #49454F;
}
.customize-input::placeholder { 
    color: #39549F; 
}
.margin-input-email {
    margin: 1.5rem 0 2rem;
}
.form-login {
    width: 80%
}
.footer-login {
    position: absolute;
    top: 95%;
    left: 80%;
    color: #fff;
    font-weight: 400;
    font-size: 11px;
}
@media (max-width: 870px) {
    .circle-white {
        border-radius: 50% / 13%;
        width: 200%;
        height: 100%;
        transform: translate(-25%, -27%);
    }
    .container-login-internal {
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .form-login {
        width: 100%
    }
    .login {
        padding: 1rem;
        justify-content: flex-start;
        height: 50%;
    }
    .text-hide-mobile {
        display: none;
    }
    .margin-login-mobile {
        margin-top: 5rem;
    }
    .animation-icon {
        padding: 2rem;
        margin-top: 15rem;
    }
    .icon-delay {
        font-size: 15px;
        animation: none;
        text-align: center;
    }
    .animation-icon img {
        margin-right: 1rem;
    } 
    .footer-login {
        position: initial;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
    .carousel-box {
        -moz-animation: automation 15s infinite linear;
        -o-animation: automation 15s infinite linear;
        -webkit-animation: automation 15s infinite linear;
        animation: automation 15s infinite linear;
        -webkit-transition: all 0.75s ease;
        -moz-transition: all 0.75s ease;
        -ms-transition: all 0.75s ease;
        -o-transition: all 0.75s ease;
        transition: all 0.75s ease;
        width: 400%;
    }
    .carousel-content {
        margin: 0 auto;
        overflow: hidden;
        text-align: left;
    }
    .carousel-item-text {
        float: left;
        width: 25%;
    }
    /* Animación para realizar el scroll automatico horizontal en el carrusel*/
    @-moz-keyframes automation {
        0% {
            margin-left: 0;
        }
        25% {
            margin-left: 0;
        }
        30% {
            margin-left: -100%;
        }
        45% {
            margin-left: -100%;
        }
        50% {
            margin-left: -200%;
        }
        65% {
            margin-left: -200%;
        }
        70% {
            margin-left: -300%;
        }
        95% {
            margin-left: -300%;
        }
        100% {
            margin-left: 0;
        }
    }
    @-webkit-keyframes automation {
        0% {
            margin-left: 0;
        }
        25% {
            margin-left: 0;
        }
        30% {
            margin-left: -100%;
        }
        45% {
            margin-left: -100%;
        }
        50% {
            margin-left: -200%;
        }
        65% {
            margin-left: -200%;
        }
        70% {
            margin-left: -300%;
        }
        95% {
            margin-left: -300%;
        }
        100% {
            margin-left: 0;
        }
    }
    @keyframes automation {
        0% {
            margin-left: 0;
        }
        25% {
            margin-left: 0;
        }
        30% {
            margin-left: -100%;
        }
        45% {
            margin-left: -100%;
        }
        50% {
            margin-left: -200%;
        }
        65% {
            margin-left: -200%;
        }
        70% {
            margin-left: -300%;
        }
        95% {
            margin-left: -300%;
        }
        100% {
            margin-left: 0;
        }
    }
}
</style>
